import React, { FC } from 'react';
import classNames from 'classnames';

import extractUrlFromMultiPicker from 'gatsby-theme-dettol/src/utils/urlMultiPicker';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import UmbracoImage from 'gatsby-theme-dettol/src/components/common/Image/UmbracoImage';
import DangerouslySetInnerHtml from 'gatsby-theme-dettol/src/components/common/DangerouslySetInnerHtml';
import HTag from 'gatsby-theme-dettol/src/components/common/HTag';
import IntroProducts from 'gatsby-theme-dettol/src/components/Intro/IntroProducts';
import { TIntroComponentProps } from 'gatsby-theme-dettol/src/components/Intro/models';

import 'gatsby-theme-dettol/src/components/Intro/Intro.scss';
import './IntroOverride.scss';

export const Intro: FC<TIntroComponentProps> = ({
  regularTitle,
  boldTitle,
  descriptionText,
  buttonText,
  image,
  imageAlt,
  buttonURL,
  introProducts,
  sectionLandmark,
}) => {
  const isSingleProduct = introProducts?.products?.length === 1;
  const productHolderClass = classNames('dt-intro__products-holder', {
    'dt-intro__products-holder--single-element': isSingleProduct,
  });

  return (
    <section className="dt-intro dt-container-wrapper" aria-label={sectionLandmark}>
      <div className="dt-intro__holder">
        <div className="dt-intro__img-holder">
          <UmbracoImage className="dt-intro__img" image={image} alt={imageAlt} />
        </div>

        <Container fluid className="dt-intro__content">
          <HTag size={1} regularText={regularTitle} boldText={boldTitle} />
          <DangerouslySetInnerHtml html={descriptionText} className="dt-intro__text" />
          <Button href={extractUrlFromMultiPicker(buttonURL)} size="lg">
            {buttonText}
          </Button>
        </Container>
      </div>
      {introProducts?.products ? (
        <Container className="dt-intro__products" fluid>
          <div className={productHolderClass}>
            <IntroProducts products={introProducts.products} />
          </div>
        </Container>
      ) : null}
    </section>
  );
};

export default Intro;
